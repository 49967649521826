<template>
  <section class="min-h-screen bg-gray-100 page-articles">
    <div
      v-if="initializing"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <div class="flex flex-col w-screen min-h-screen">
        <van-sticky>
          <van-search
            v-model="form.keyword"
            show-action
            label="搜索"
            placeholder="请输入搜索关键字"
            :disabled="refreshing"
            @search="doSearch"
          >
            <template #action>
              <div @click="doSearch">
                搜索
              </div>
            </template>
          </van-search>
          <van-dropdown-menu class="conditions-container border-b-1 border-t-1">
            <van-dropdown-item
              v-model="form.status"
              :options="statuses"
              @change="doSearch"
            />

            <van-dropdown-item
              v-model="form.year"
              :options="years"
              @change="doSearch"
            />
          </van-dropdown-menu>
        </van-sticky>
        <van-pull-refresh
          v-if="loading || articles.total"
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="articles.to === null || articles.to == articles.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="pb-12"
            @load="fetch"
          >
            <div
              v-for="item of articles.data"
              :key="`item-${item._id}`"
              class="p-3 m-3 bg-white border-b border-gray-200 rounded-lg list-card"
              @click="goLink(item)"
            >
              <div class="flex flex-row items-center justify-start">
                <div
                  v-if="item.image"
                  class="thumbnail"
                >
                  <van-image
                    width="80"
                    height="80"
                    :src="`${item.image}!square_head_image`"
                  />
                </div>
                <div
                  class="flex flex-col items-start justify-between flex-1 pl-2 content"
                  :style="item.image ? 'min-height: 80px;' : ''"
                >
                  <div class="mb-2 font-semibold leading-snug">
                    <van-tag
                      v-if="item.internal"
                      round
                      type="danger"
                      class="ml-1"
                    >
                      内部资料
                    </van-tag>
                    {{ item.title
                    }}
                    <template v-if="!item.internal">
                      <van-tag
                        v-if="item.onTop"
                        round
                        type="danger"
                        class="ml-1"
                      >
                        置顶
                      </van-tag>
                      <van-tag
                        plain
                        :type="
                          articleStatus[item.status]
                            ? articleStatus[item.status]
                            : 'primary'
                        "
                        class="ml-1"
                      >
                        {{ item.status }}
                      </van-tag>

                      <van-tag
                        v-if="item.authorizationRequired"
                        plain
                        type="warning"
                        class="ml-1"
                      >
                        仅限业主访问
                      </van-tag>

                      <van-tag
                        v-if="item.archivedAt && $dayjs(item.archivedAt).isBefore($dayjs())"
                        color="#aaa"
                        plain
                        class="ml-1"
                      >
                        已归档
                      </van-tag>
                    </template>
                  </div>
                  <div
                    class="flex flex-row items-center justify-between w-full text-sm text-gray-400 "
                  >
                    <span><van-icon name="bullhorn-o" /> {{ item.category }}</span>
                    <span><van-icon name="calendar-o" />
                      {{ $dayjs(item.published_at).format('YYYY-MM-DD') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
        <van-empty
          v-if="!loading && articles.total == 0"
          description="暂无相关专题内容"
        >
          <a
            href="#"
            class="text-red-500"
            @click="reset"
          >清除过滤条件</a>
        </van-empty>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'WorkspaceCms',
  mixins: [share],
  data() {
    return {
      collection: null,
      loading: true,
      initializing: true,
      refreshing: false,
      form: {
        status: '',
        year: '',
        keyword: ''
      },
      articles: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      },
      years: []
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb', 'db', '_']),
    ...mapState('Common/Config', ['articleStatus']),
    statuses() {
      return [{ text: '全部状态', value: '' }].concat(
        Object.keys(this.articleStatus).map(item => ({
          text: item,
          value: item
        }))
      )
    }
  },
  async mounted() {
    this.collection = this.$route.params.collection
    this.disableShareMenu()
    await this.getOldest()
    this.fetch()
  },
  methods: {
    async doSearch() {
      this.initializing = true
      let query = Object.assign({}, this.form, {
        _timestamp: +new Date()
      })

      await this.$router.push({ name: 'workspace.cms', query })
      this.onRefresh()
    },
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async getOldest() {
      const $ = this.db.command.aggregate
      try {
        let { list } = await this.db
          .collection('fire')
          .aggregate()
          .group({
            _id: 1,
            oldest: $.min('$published_at')
          })
          .project({
            _id: 0,
            oldest: 1
          })
          .end()
        // eslint-disable-next-line no-console
        // console.log('最老的发布时间', Number(this.$dayjs(list[0].oldest).format('YYYY')))
        if (list?.[0]?.oldest) {
          let years = [{ text: '全部时间', value: '' }]
          let from = Number(this.$dayjs(list[0].oldest).format('YYYY'))
          let year = new Date().getFullYear()
          for (let i = year; i >= from; i--) {
            years.push({ text: `${i}年`, value: i })
          }
          this.years = years
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.articles = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let query =
        Object.keys(this.$route.query).length > 0
          ? this.$route.query
          : { status: '', year: '', keyword: '' }
      // eslint-disable-next-line no-console
      console.log('查询信息', query)
      this.form = Object.assign({ year: '', keyword: '', status: '' }, query)
      let page = (this.articles.current_page ?? 0) + 1

      this.loading = true
      let conditions = [
        {
          deletedAt: this._.exists(false)
        }
      ]
      if (this.form.keyword) {
        let keyword = this.form.keyword
        // eslint-disable-next-line no-console
        console.warn('搜索关键字', keyword)
        conditions.push(
          this._.or([
            {
              title: this.db.RegExp({
                regexp: '.*' + keyword + '.*',
                options: 'i'
              })
            },
            {
              content: this.db.RegExp({
                regexp: '.*' + keyword + '.*',
                options: 'i'
              })
            },
            {
              markdown: this.db.RegExp({
                regexp: '.*' + keyword + '.*',
                options: 'i'
              })
            }
          ])
        )
      }
      if (this.form.status) {
        conditions.push({
          status:
            typeof this.form.status == 'string'
              ? this._.eq(this.form.status)
              : this._.in(this.form.status)
        })
      }
      if (this.form.year) {
        conditions.push(
          this._.and([
            {
              published_at: this._.lte(
                this.$dayjs(`${this.form.year}-12-31 23:59:59`).valueOf()
              )
            },
            {
              published_at: this._.gte(
                this.$dayjs(`${this.form.year}-01-01 00:00:00`).valueOf()
              )
            }
          ])
        )
      }
      query = {}
      if (conditions.length == 1) {
        query = conditions[0]
      }
      if (conditions.length > 1) {
        query = this._.and(conditions)
      }
      try {
        let countResult = await this.db
          .collection(this.collection)
          .where(query)
          .count()
        const total = countResult?.total ?? 0
        // eslint-disable-next-line no-console
        console.warn('总共有数据', total, this.articles, page)

        let result = await this.db
          .collection(this.collection)
          .where(query)
          .orderBy('published_at', 'desc')
          .skip((page - 1) * 10)
          .limit(10)
          .get()
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, result)
        let articles = {
          data: this.articles.data.concat(result.data),
          to: this.articles.to + result.data.length,
          current_page: page,
          total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', articles)
        this.$set(this, 'articles', articles)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.articles = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    reset() {
      this.form = {
        status: '',
        year: '',
        keyword: ''
      }
      this.doSearch()
    },
    goLink(item) {
      this.$router.push({
        name: 'workspace.cms.edit',
        params: { id: item._id, collection: this.collection }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.articles-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .van-cell__value {
    display: none;
  }
}
::v-deep .conditions-container {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }
}
::v-deep .van-search--show-action {
  padding-right: 12px;
}
</style>
